import {
  DetailDataBlock,
  Document,
  Favorites,
  Floorplans,
  Grid,
  Header,
  Link,
  Properties,
} from "@/components";

import { useLoaderData } from "react-router-dom";

import ScrollTarget from "@/hooks/ScrollTarget";
import { PhaseQuery, PlotLoader, PlotQuery, ProjectQuery } from "@/loaders";
import {
  buttonClasses,
  buttonIconClasses,
  getProperties,
  isAvailable,
  price as priceFormatter,
} from "@/services";
import { Hotspot, Plot } from "@/types";
import {
  faBook,
  faCompassDrafting,
  faFileDownload,
  faHandWave,
  faInfoCircle,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { useSuspenseQueries } from "@tanstack/react-query";

import Recommendations from "@/components/Plot/Recommendations";

import { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useFavorites from "@/hooks/useFavorites";

export default function PlotPage() {
  const { projectSlug, phaseSlug, plotId } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof PlotLoader>>
  >;
  const { updateFavoriteIds, favoritedIds } = useFavorites();

  const {
    1: {
      data: { project, statuses, plots, layers },
    },
    2: {
      data: { plot },
    },
  } = useSuspenseQueries({
    queries: [
      ProjectQuery(projectSlug),
      PhaseQuery(projectSlug, phaseSlug),
      PlotQuery(projectSlug, phaseSlug, plotId),
    ],
  });

  const hotspotsByPlotId = project.hotspots.reduce<{
    [key: Plot["id"]]: Hotspot;
  }>((acc, hotspot) => {
    if (hotspot.entity_type !== "App\\Models\\Plot") return acc;
    acc[hotspot.entity_id] = hotspot;
    return acc;
  }, {});

  const properties = getProperties(plot);
  const hasProperties =
    Object.values(properties).filter((section) =>
      Object.values(section).some((property) => property.value !== null)
    ).length > 0;

  const validFloorplans = useMemo(() => {
    const validImageTypes = [
      "image/apng",
      "image/avif ",
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/webp",
    ];

    return plot.floorplans.filter((image) => {
      const { mime_type } = image;
      if (!validImageTypes.includes(mime_type)) {
        console.error("floorplan is not a valid image", image);
        return false;
      }

      return true;
    });
  }, [plot]);

  const handleWishlistClick = (plot: Plot) => {
    if (favoritedIds.includes(plot.id)) return;

    updateFavoriteIds(plot.id);
  };

  const hasDescription = plot.description !== null;
  const hasFloorplans = validFloorplans !== null && validFloorplans.length > 0;
  const hasDocuments = plot.documents !== null && plot.documents.length > 0;

  return (
    <div className="pt-8 pb-20 lg:pt-12 lg:pb-24">
      <Grid className="grid grid-cols-plotPage">
        <Header plot={plot} status={statuses[plot.status]} />
      </Grid>

      <Grid className="grid mt-4 grid-cols-plotPage lg:mt-12">
        <div className="flex flex-wrap gap-2 col-span-full lg:gap-4 xl:col-start-2 lg:col-end-14">
          {hasDescription && (
            <Link to="#description" variant="primary" icon={faBook}>
              <span>Omschrijving</span>
            </Link>
          )}

          {hasProperties && (
            <Link to="#properties" variant="primary" icon={faInfoCircle}>
              <span>Woningspecificaties</span>
            </Link>
          )}

          {hasFloorplans && (
            <Link to="#floorplans" variant="primary" icon={faCompassDrafting}>
              <span>Plattegrond(en)</span>
            </Link>
          )}

          {hasDocuments && (
            <Link to="#bestanden" variant="primary" icon={faFileDownload}>
              <span>Bestanden</span>
            </Link>
          )}
        </div>
      </Grid>

      <div className="flex flex-col px-6 mx-auto gap-y-12 lg:grid-area-plot-page lg:max-w-grid">
        <div
          className="grid mt-12 auto-rows-min gap-y-12 grid-cols-13 lg:gap-y-24"
          style={{ gridArea: "a" }}
        >
          {hasDescription && (
            <PlotSection
              target="description"
              title={`Omschrijving woningtype ${plot.house_type_name}`}
            >
              <div
                className="!leading-7 flex flex-col gap-4 body"
                dangerouslySetInnerHTML={{ __html: plot.description }}
              ></div>
            </PlotSection>
          )}

          {hasProperties && (
            <PlotSection target="properties" title="Woningspecificaties">
              <Properties properties={properties} />
            </PlotSection>
          )}

          {hasFloorplans && (
            <PlotSection target="floorplans" title="Plattegrond(en)">
              {plot.floorplans && (
                <Floorplans
                  images={validFloorplans}
                  className="col-span-full"
                />
              )}
            </PlotSection>
          )}

          {hasDocuments && (
            <PlotSection target="bestanden" title="Bestanden">
              <ul className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
                {plot.documents?.map((document) => (
                  <li key={document.url}>
                    <Document document={document} />
                  </li>
                ))}
              </ul>
            </PlotSection>
          )}
        </div>

        <div
          className={clsx(
            plot.attachments.length > 0
              ? "lg:-translate-y-40"
              : "lg:-translate-y-9"
          )}
          style={{ gridArea: "b" }}
        >
          <div className="grid grid-cols-10">
            <div className="flex flex-col gap-4 col-span-full xl:col-start-2 xl:col-end-[-2] lg:sticky lg:top-48">
              <DetailDataBlock plot={plot} statuses={statuses} />
              <Favorites plots={plots} statuses={statuses} />
            </div>
          </div>
        </div>

        <div className="grid col-span-full grid-cols-plotPage">
          <Recommendations
            current={plot}
            plots={plots}
            hotspotsByPlotId={hotspotsByPlotId}
            statuses={statuses}
            layers={layers}
            className="flex flex-col gap-4 col-span-full lg:col-start-2 lg:col-end-[-2] lg:gap-y-8"
          />
        </div>
      </div>

      {isAvailable(statuses[plot.status]) && (
        <div className="fixed bottom-0 left-0 right-0 z-50 flex flex-wrap items-center justify-between w-full px-6 py-3 bg-white border-t border-t-black/10 lg:hidden">
          <div className="flex flex-col">
            <span className="font-bold h3">
              {priceFormatter(parseInt(plot.price), plot.project_type)}
            </span>
            <span className="!leading-tight body">Bwnr. {plot.name}</span>
          </div>

          <Link
            to={`/projects/${projectSlug}/phases/${phaseSlug}/favorieten`}
            className={buttonClasses("primary")}
            onClick={() => handleWishlistClick(plot)}
          >
            <FontAwesomeIcon
              icon={faHandWave}
              className={buttonIconClasses("primary")}
            />
            <span className="whitespace-nowrap">Ik wil deze woning</span>
          </Link>
        </div>
      )}
    </div>
  );
}

type PlotSectionProps = {
  target: string;
  title: string;
  children?: React.ReactNode;
};
function PlotSection({ target, title, children }: PlotSectionProps) {
  return (
    <section className="flex flex-col gap-y-4 col-span-full xl:col-start-2 lg:col-end-[-2] lg:gap-y-8">
      <h2 className="h2 col-span-full xl:col-start-2 lg:col-end-[-2]">
        <ScrollTarget id={target} className="-translate-y-20" />
        <span>{title}</span>
      </h2>

      {children}
    </section>
  );
}
