import { useCallback, useEffect, useRef } from "react";

import { DesktopPopup, Elevator, Hotspot } from "@/components";
import { generateWindow } from "@/services/Layer";
import {
  Hotspot as HotspotType,
  Layer,
  Media,
  Phase,
  Plot,
  Project,
} from "@/types";
import {
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CRS, LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { renderToString } from "react-dom/server";
import {
  FeatureGroup,
  ImageOverlay,
  MapContainer,
  Pane,
  ZoomControl,
} from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import "./Projectmap.css";

type PlotMapProps = {
  project: Project;
  statuses: Phase["statuses"];
  layers: { [key: Layer["slug"]]: Layer };
  activeLayer: Layer;
  filteredPlotsByLayer: { [key: Layer["id"]]: Plot[] };
  plotsById: { [key: Layer["id"]]: Plot };
  filteredPlots: { [key: Plot["id"]]: Plot };
  hotspots: { [key: HotspotType["id"]]: HotspotType };
  className?: string;
};

export default function ProjectMap({
  project,
  statuses,
  activeLayer,
  plotsById,
  filteredPlots,
  hotspots,
  className,
}: PlotMapProps) {
  const projectLayers = project.layers;
  const [searchParams] = useSearchParams();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const backgroundImageRef = useRef<L.ImageOverlay>(null);
  const imageOverlayRef = useRef<L.ImageOverlay>(null);
  const mapRef = useRef<L.Map>(null);

  const validHotspots = project.hotspots.filter(
    (hotspot) => hotspot.layer_id === activeLayer.id
  );

  const searchParamHotspot = searchParams.get("hotspot");
  const activeHotspot = searchParamHotspot
    ? hotspots[parseInt(searchParamHotspot)]
    : null;

  const preloadableLayers = useCallback(() => {
    const reversed = projectLayers.toReversed();
    const currentIndex = reversed.findIndex(
      (layer) => layer.id === activeLayer.id
    );

    const { slidingWindow } = generateWindow(currentIndex, reversed);

    return slidingWindow.filter((layer) => {
      const current = layer.id === activeLayer.id;
      const parent = layer.id === activeLayer.parent_id;
      const child = layer.parent_id === activeLayer.id;
      const sibling = layer.parent_id === activeLayer.parent_id;
      const hasValidDimensions =
        layer.background.width && layer.background.height;
      return !current && hasValidDimensions && (parent || sibling || child);
    });
  }, [projectLayers, activeLayer.id, activeLayer.parent_id]);

  /**
   * Creates a LatLngBounds object based on the dimensions of the background image.
   */
  const getBounds = (background: Media): LatLngBounds | null => {
    const width = background.width ?? 2750;
    const height = background.height ?? 1547;

    if (!background.width || !background.height) {
      console.error("Background width and/or height are invalid", background);
    }

    return new LatLngBounds([0, 0], [height, width]);
  };

  useEffect(() => {
    const backgroundBounds = getBounds(activeLayer.background);

    if (backgroundBounds === null || mapRef.current === null) return;

    if (
      imageOverlayRef.current !== null &&
      backgroundImageRef.current !== null
    ) {
      imageOverlayRef.current
        .setUrl(activeLayer.background.url)
        .setBounds(backgroundBounds);

      backgroundImageRef.current
        .setUrl(activeLayer.background.url)
        .setBounds(backgroundBounds);
    }

    const center = backgroundBounds.getCenter();

    if (!center) {
      console.error(
        `$hotspotBounds and/or $backgroundBounds are not valid LatLngBounds`,
        { backgroundBounds: backgroundBounds }
      );

      return;
    }

    mapRef.current
      .setMaxBounds(backgroundBounds.pad(0.2))
      .setView(center, mapRef.current.getBoundsZoom(backgroundBounds, true), {
        animate: false,
      });
  }, [activeLayer.id, activeLayer.background]);

  const bounds = getBounds(activeLayer.background);

  preloadableLayers().forEach((layer) => {
    const image = new Image();
    image.src = layer.background.url;
    image.width = layer.background.width || 0;
    image.height = layer.background.height || 0;
  });

  return (
    <div className={clsx(className)}>
      <Elevator layers={projectLayers} activeLayer={activeLayer} />

      <div
        className={clsx(
          "relative z-10 w-dvw h-dvh bg-secondary rounded map transition-opacity duration-1000",
          activeLayer.background?.url && "opacity-0"
        )}
      >
        {bounds && (
          <MapContainer
            attributionControl={false}
            crs={CRS.Simple}
            className="w-dvw h-dvh bg-secondary"
            zoomControl={false}
            zoom={0}
            minZoom={-3}
            zoomSnap={0}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            center={bounds.getCenter()}
            maxBounds={bounds}
            ref={mapRef}
          >
            {activeLayer.background.url && (
              <Pane name="backgroundBlurPane" style={{ zIndex: 400 }}>
                <ImageOverlay
                  ref={backgroundImageRef}
                  url={activeLayer.background.url}
                  bounds={bounds.pad(0.2)}
                  interactive={false}
                  className={"map-background"}
                />
              </Pane>
            )}

            {activeLayer.background.url && (
              <Pane name="backgroundPane" style={{ zIndex: 500 }}>
                <ImageOverlay
                  ref={imageOverlayRef}
                  url={activeLayer.background.url}
                  bounds={bounds}
                  interactive={false}
                  eventHandlers={{
                    load: () => {
                      if (!mapRef.current) return;

                      mapRef.current
                        .setMaxBounds(bounds.pad(0.2))
                        .setView(
                          bounds.getCenter(),
                          mapRef.current.getBoundsZoom(bounds, true),
                          { animate: false }
                        );

                      mapRef.current
                        ?.getContainer()
                        .parentNode.classList.add("!opacity-100");
                    },
                  }}
                />
              </Pane>
            )}

            <Pane name="hotspotPane" style={{ zIndex: 600 }}>
              <FeatureGroup>
                {validHotspots.map((hotspot, index) => {
                  const plot = plotsById[hotspot.entity_id];

                  if (!plot) {
                    console.error(`Plot ${hotspot.entity_id} can't be found`);
                    return null;
                  }

                  const active =
                    activeHotspot?.id === hotspot.id &&
                    activeHotspot?.entity_type === hotspot.entity_type;

                  return (
                    <Hotspot
                      key={`${index}-${hotspot.id}-${hotspot.entity_id}-${hotspot.entity_type}`}
                      hotspot={hotspot}
                      status={statuses[plot.status]}
                      active={active}
                      matches={filteredPlots[plot.id] !== undefined}
                      background={activeLayer.background}
                    />
                  );
                })}
              </FeatureGroup>
            </Pane>

            <ZoomControl
              position="bottomright"
              zoomInText={renderToString(
                <span
                  className={clsx(
                    "text-base leading-none px-3.5 py-3.5 flex items-center justify-center bg-primary text-primary-contrast transition-colors",
                    "hocus:bg-secondary hocus:text-secondary-contrast"
                  )}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassPlus}
                    className="w-4 h-4"
                  />
                </span>
              )}
              zoomOutText={renderToString(
                <span className="text-base leading-none px-3.5 py-3.5 flex items-center justify-center bg-primary text-primary-contrast hocus:bg-secondary hocus:text-secondary-contrast transition-colors">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassMinus}
                    className="w-4 h-4"
                  />
                </span>
              )}
            />

            {isDesktop && activeHotspot && (
              <DesktopPopup
                hotspot={activeHotspot}
                plots={plotsById}
                statuses={statuses}
                background={activeLayer.background}
              />
            )}
          </MapContainer>
        )}
      </div>
    </div>
  );
}
